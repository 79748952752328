<template>
  <div>
    <VTitle :title="$t('app.memberships', 2)" class="section-title" />

    <div class="grid responsive-cols gap-5">
      <div>
        <Roles :memberships="user.roles" hide-actions />
      </div>

      <div>
        <Departments :memberships="user.departments" hide-actions />

        <Sites class="mt-5" :memberships="user.sites" hide-actions />
      </div>
    </div>
  </div>
</template>

<script>
import { provide, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Components
import Departments from "@/views/administration/users/memberships/Departments";
import Roles from "@/views/administration/users/memberships/Roles";
import Sites from "@/views/administration/users/memberships/Sites";
import VTitle from "@/components/VTitle";

export default {
  components: {
    Departments,
    Roles,
    Sites,
    VTitle
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const store = useStore();

    // Provide
    provide("isAdding", false);

    // COMPUTED
    const documentTitle = computed(() => {
      const title = `${t("app.memberships", 2)} - ${t("app.profile")}`;

      const { firstname, lastname } = props.user ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // METHODS
    const getData = async () => {
      const response = await store.dispatch("api/request", {
        endpoint: "my.memberships"
      });

      store.dispatch("user/updateUser", response?.payload?.data || {});
    };

    // LIFECYCLE HOOKS
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle
    };
  }
};
</script>
